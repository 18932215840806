@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    p {
        @apply pb-2;
    }
    a {
        @apply text-purple underline;
    }
}

@layer utilities {
    .h-screen {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
}
